import { GatsbyBrowser } from "gatsby";
import React from "react";

// eslint-disable-next-line react/display-name,react/prop-types
export const WrapRootElement: GatsbyBrowser["wrapRootElement"] = ({
  element,
}) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts

  return <>{element}</>;
};
