/**
 * External dependencies.
 */
import React from "react";
import { GatsbyBrowser, Script } from "gatsby";

/**
 * Internal dependencies.
 */

/**
 * Page Element which is a wrapper to the app.
 *
 * @param {Object} element App element.
 *  @param {Object} props
 * @return {*}
 */
export const WrapPageProvider: GatsbyBrowser["wrapPageElement"] = ({
  element,
  props,
}) => {
  console.log("🚀 ~ props:", props);
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  //   const dispatch = useDispatch();

  //   const UTM = {
  //     campaign: getUtm("utm_campaign="),
  //     medium: getUtm("utm_medium="),
  //     source: getUtm("utm_source="),
  //     content: getUtm("utm_content="),
  //     term: getUtm("utm_term="),
  //     referrer: getUtm("utm_referrer="),
  //   };

  //   useEffect(() => {
  //     dispatch(setUtm(UTM));
  //   }, [dispatch]);

  return (
    <>
      {element}
      {/* <YMInitializer
        accounts={[84473377]}
        options={{ webvisor: true }}
        version="2"
      /> */}
      <Script
        id="gtm-data-layer"
        dangerouslySetInnerHTML={{
          __html: `window.dataLayer = [];`,
        }}
      />

      {/* <Script
        id="roistat"
        dangerouslySetInnerHTML={{
          __html: `(function(w, d, s, h, id) {
    w.roistatProjectId = id; w.roistatHost = h;
    var p = d.location.protocol == "https:" ? "https://" : "http://";
    var u = /^.*roistat_visit=[^;]+(.*)?$/.test(d.cookie) ? "/dist/module.js" : "/api/site/1.0/"+id+"/init?referrer="+encodeURIComponent(d.location.href);
    var js = d.createElement(s); js.charset="UTF-8"; js.async = 1; js.src = p+h+u; var js2 = d.getElementsByTagName(s)[0]; js2.parentNode.insertBefore(js, js2);
})(window, document, 'script', 'cloud.roistat.com', 'f24077b5ae3e809837e3ab3d0dbe1e9e');
`,
        }}
      /> */}
    </>
  );
};
