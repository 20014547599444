/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// import "./src/styles/global.css";

import "./src/styles/index";

import { WrapRootElement } from "./src/Providers/wrap-root-element";
import { WrapPageProvider } from "./src/Providers/wrap-page-element";
export const wrapRootElement = WrapRootElement;
export const wrapPageElement = WrapPageProvider;
